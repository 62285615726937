<template>
	<div class="enterprise">
		<div style="background-color: #fff;">
			<el-row>
				<gsLeft :userFormW="subform"></gsLeft>
				<el-col :span="20">
					<div class="menuContent">

						<div class="manageInfo">
							<div class="manageInfo-title">管理招聘信息</div>
							<el-form ref="form" :model="formInline" label-width="5.5vw">
								<el-form-item label="创建时间">
									<el-col :span="11">
										<el-date-picker v-model="formInline.createTime" type="datetimerange"
											align="right" unlink-panels clearable value-format="yyyy-MM-dd hh:mm:ss"
											range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
											@change="dateChange"></el-date-picker>
									</el-col>
								</el-form-item>
								<el-form-item label="招聘状态">
									<el-button type="text" @click="checkStatus('')">全部</el-button>
									<el-button type="text" @click="checkStatus(1)">已显示</el-button>
									<el-button type="text" @click="checkStatus(2)">已隐藏</el-button>
									<el-button type="text" @click="checkStatus(3)">已置顶</el-button>
								</el-form-item>
								<el-form-item label="职务搜索" class="enterprise-search">
									<el-input v-model="formInline.job" placeholder="请输入招聘职务"
										@input="clearInput"></el-input>
									<el-button ref="searchBtn" :type="formInline.job ? 'primary' : 'info'"
										@click="initData(formInline)">搜索</el-button>
									<el-button class="clearfix" @click="allRefresh">一键刷新</el-button>
								</el-form-item>
							</el-form>
							<el-table :data="tableData" style="width: 100%" :header-cell-style="{
								'background-color': 'rgba(32, 137, 255, 0.07)',
								'color': '#333',
								'font-size': '0.833vw',
								'font-weight': '400'
							}">
								<el-table-column type="selection" width="55">
								</el-table-column>
								<el-table-column prop="job" label="职务"></el-table-column>
								<el-table-column prop="level" label="证书"></el-table-column>
								<el-table-column prop="money" label="工资"></el-table-column>
								<el-table-column prop="shipType" label="船型"></el-table-column>
								<!-- <el-table-column prop="sailLine" label="航线"></el-table-column> -->
								<el-table-column prop="tonnage" label="吨位"></el-table-column>
								<!-- <el-table-column prop="expiredDate" label="截止时间"></el-table-column> -->
								<el-table-column prop="updateTime" label="发布日期">
									<template slot-scope="scope">
										{{ $dy_tool.formatDate(scope.row.updateTime, 'yyyy-MM-dd') }}
									</template>
								</el-table-column>
								<el-table-column label="操作" width="180">
									<template slot-scope="scope">
										<el-button type="text" @click="handleHide(scope.$index, scope.row)">{{ scope.row.isOpen ? '隐藏' :
											'显示' }}</el-button>
										<el-button type="text"
											@click="jump('recruitDetail/'+ scope.row.id )">查看</el-button>
										<el-button type="text"
											@click="jump('post_recruitment_info?id=' + scope.row.id)">修改</el-button>
										<!-- <el-button type="text" @click="handleEdit(scope.$index, scope.row)">删除</el-button> -->
										<el-button type="text" @click="refresh(scope.$index, scope.row)">刷新</el-button>
										<el-button type="text" @click="stick(scope.$index, scope.row)">置顶</el-button>
										<el-button type="text" @click="del(scope.$index, scope.row)">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<div class="tableBottom">
								<div>
									<el-button type="text"
										@click="handleRefresh(scope.$index, scope.row)">批量刷新</el-button>
									<el-button type="text"
										@click="handleDelete(scope.$index, scope.row)">批量删除</el-button>
								</div>
								<div style="text-align:right;">
									<el-pagination style="margin-top:20px;text-align:right;" :current-page="currentPage"
										:page-sizes="[10, 20, 50, 100]" :page-size="10"
										layout="prev, pager, next, jumper" :total="total"
										@size-change="handleSizeChange" @current-change="handleCurrentChange" />
								</div>
							</div>
						</div>
					</div>

					<el-dialog custom-class="packageUse" title="请选择要使用的置顶套餐" :visible.sync="dialogVisible" width="30%"
						:before-close="handleClose" :show-close="false">
						<div class="takeEffect">
							<div :data-id="item.id" ref="package" style="display: inline-block"
								@click="checkPackage(idx)" class="info" v-for="(item, idx) in packetList"
								:class="{ 'check': item.isCheck }" :key="idx">
								<div class="content" style="width: 150px; margin-bottom: 0;background:none">
									<div class="bold">{{ item.packet.name }}</div>
									<div class="enterprises">
										<div>
											<div>时长（{{ unit }}）：{{ item.packet.timeLong }}</div>
											<div>次数（次）：{{ item.surplus }}</div>
											<div style="margin-top: 20px">金额：<span
													style="color: #f95022">￥{{ item.free }}</span></view>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<span slot="footer" class="dialog-footer">
							<el-button @click="dialogVisible = false">取 消</el-button>
							<el-button type="primary" @click="buyConfirm">确 定</el-button>
						</span>
					</el-dialog>
					<el-dialog title="提示" :visible.sync="showSub" width="30%">
						<span v-if="subform.subscribe" style="margin-right:20px">是否关闭订阅</span>
						<span v-else style="margin-right:20px">是否开启订阅</span>
						<el-radio v-model="subscription" :label="true">是</el-radio>
						<el-radio v-model="subscription" :label="false">否</el-radio>
						<span slot="footer" class="dialog-footer">
							<el-button @click="showSub = false">取 消</el-button>
							<el-button type="primary" @click="editSub">确 定</el-button>
						</span>
					</el-dialog>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import BaseMixin from '@/mixins/BaseMixin.vue'
	import gsLeft from "@/component/gs_left.vue";
	export default {
		mixins: [BaseMixin],
		components: {
			gsLeft
		},
		data() {
			return {
				checkIndex: '4-2',
				pickerOptions: {
					shortcuts: [{
							text: '最近一周',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: '最近一个月',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: '最近三个月',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit('pick', [start, end]);
							}
						}
					]
				},
				form: {
					date: '',
					employJob: '选项1',
					search: '',
				},
				options: [{
						value: '选项1',
						label: '所有职务'
					},
					{
						value: '选项2',
						label: '机工'
					}
				],
				tableData: [

				],
				currentPage: 1,
				total: 1,
				formInline: {
					createTime: "", // 搜索字段
					job: '',
					isTop: "",
					isOpen: '',
					page: 0, // 当前页
					size: 10,
				},
				packetList: [],
				dialogVisible: false,
				recruitId: 0,
				showSub: false,
				subscription: true,
				subform: {}
			}
		},
		methods: {
			initData(formInline) {
				this.subform = {
					id: this.vuex_user.id,
					nickName: this.vuex_user.nickName,
					avatar: this.vuex_user.avatar,
					gender: this.vuex_user.gender,
					openId: this.vuex_user.openId,
					wxOpenid: this.vuex_user.wxOpenid,
					phone: this.vuex_user.phone,
					password: this.vuex_user.password,
					name: this.vuex_user.name,
					simpleName: this.vuex_user.simpleName,
					province: this.vuex_user.province,
					city: this.vuex_user.city,
					district: this.vuex_user.district,
					address: this.vuex_user.address,
					postalCode: this.vuex_user.postalCode,
					website: this.vuex_user.website,
					intro: this.vuex_user.intro,
					subscribe: this.vuex_user.subscribe,
					isPass: this.vuex_user.isPass,
					openAgency: this.vuex_user.openAgency,
					createTime: this.vuex_user.createTime,
					autid: this.vuex_user.autid
				}
				this.subscription = this.subform.subscribe
				this.$u.api.companyRecruitPC(formInline).then(res => {
					res.data.content.forEach(e => {
						if (e.salaryRoof == 0) {
							e.money = '面议'
						} else {
							e.money = e.salaryRoof
						}
					})
					this.tableData = res.data.content
					this.total = res.data.totalElements

				})
			},
			onSubmit() {
				console.log('submit!');
			},
			dateChange(e) {

				this.initData(this.formInline)
			},
			clearInput(e) {
				if (e == '') {
					this.formInline = {}
					this.formInline.page = 0
					this.formInline.size = 10
					this.initData(this.formInline)
				}
			},
			handleHide(index, row) {
				if (row.isOpen) {
					this.$u.api.recruitOpenChanges({
						id: row.id,
						isOpen: !row.isOpen
					}).then(res => {
						this.uni.showToast('招聘隐藏成功')
						this.initData()
					})
				} else {
					this.$u.api.recruitOpenChanges({
						id: row.id,
						isOpen: !row.isOpen
					}).then(res => {
						this.uni.showToast('招聘显示成功')
						this.initData()
					})
				}
			},
			allRefresh() {
				this.$u.api.recruitOneKeyRefresh().then(res => {
					this.uni.showToast('一键刷新招聘成功')
					this.initData(this.formInline)
				})
			},
			refresh(index, row) {
				this.$u.api.recruitRefresh({
					id: row.id
				}).then(res => {
					this.uni.showToast('刷新成功')
				}).catch(() => {
					this.uni.showToast('每隔15分钟可刷新一次')
				})
				console.log('刷新');
			},
			stick(index, row) {
				this.recruitId = row.id
				this.$u.api.topRecruit({
					id: row.id
				}).then((res) => {
					if (res.data !== -1) {
						this.uni.showToast('置顶成功')
					} else {
						this.jump("ec_buy_package")
					}
				});
			},
			del(index, row) {
				this.$u.api.delRecruit(row.id + '').then(res => {
					this.uni.showToast('招聘删除成功')
					this.initData()

				})
			},
			checkPackage(id) {
				this.packetList.forEach(element => {
					element.isCheck = false
				})
				this.packetList[id].isCheck = !this.packetList[id].isCheck
				this.orderId = ''
				if (this.packetList[id].isCheck) {
					this.orderId = this.packetList[id].id
				}

			},
			buyConfirm() {
				this.$u.api.recruitTop({
					id: this.recruitId,
					orderId: this.orderId
				}).then(res => {
					this.uni.showToast({
						title: '招聘已置顶',
						icon: 'none'
					})
					this.dialogVisible = false
				})
			},
			handleClose() {},

			handleRefresh(index, row) {},
			handleDelete(index, row) {},
			checkStatus(e) {
				if (e == '') {
					// 	this.formInline = {
					//   createTime: "", // 搜索字段
					// 	job: '',
					//   isTop: "", 
					//   isOpen: '',
					//   page: 0, // 当前页
					//   size: 10,
					// }
					this.initData(this.formInline)
				} else if (e == 1) {
					this.formInline.isOpen = true
					this.initData(this.formInline)
					this.formInline.isOpen = ''
				} else if (e == 2) {
					this.formInline.isOpen = false
					this.initData(this.formInline)
					this.formInline.isOpen = ''
				} else {
					this.formInline.isTop = true
					this.initData(this.formInline)
					this.formInline.isTop = ''
				}
				// this.initData(this.formInline)
			},
			editSub() {
				if (this.subscription) {
					this.$u.api.updateSubscribe(this.subscription + '').then(res => {
						this.uni.showToast('订阅开启成功')
						this.subform.subscribe = true
						this.$u.vuex('vuex_user', this.subform)
						this.showSub = false

					})
				} else {
					this.$u.api.updateSubscribe(this.subscription + '').then(res => {
						this.uni.showToast('订阅关闭成功')
						this.subform.subscribe = false
						this.$u.vuex('vuex_user', this.subform)
						this.showSub = false

					})
				}

			},
			handleSizeChange(val) {
				this.formInline.size = val
				this.initData(this.formInline)
			},
			handleCurrentChange(val) {
				this.formInline.page = val - 1
				this.initData(this.formInline)
			},
			check(index) {
				this.checkIndex = index
				if (index == '1') {
					this.jump('enterprise_index')
				} else if (index == '2') {
					this.jump('ec_account_manage')
				} else if (index == '3') {
					this.jump('ec_buy_package')
				} else if (index == '4-1') {
					if (this.vuex_user.isPass === true && this.vuex_user.autid != 2) {
						this.uni.showToast('您的企业审核暂未通过')
						return
					}
					this.jump('post_recruitment_info')
				} else if (index == '4-2') {
					this.jump('manage_recruitment_info')
				} else if (index == '4-3') {
					this.jump('resume_delivery_received')
				} else if (index == '4-4') {
					this.jump('collected_resume')
				} else if (index == '5-1') {
					this.jump('ec_training_info')
				} else if (index == '5') {
					this.jump('ec_training_order')
				} else if (index == '5-3') {
					this.jump('training_school')
				} else if (index == '7') {
					if (this.vuex_user.isPass === true && this.vuex_user.autid != 2) {
						this.uni.showToast('您的企业审核暂未通过')
						return
					}
					this.jump('corporate_announcements')
				} else if (index == '8') {
					// this.$u.api.companyLoginInfo().then(res => {
					// 	if(res.data.openAgency) {
					if (this.vuex_user.openAgency) {
						this.jump('ec_mediation_guarantee')
					} else {
						this.uni.showToast('您未开通中介担保')
					}
					// })
				} else if (index == '9') {
					this.jump('published_data')
				} else if (index == '10') {
					this.showSub = true
				}
			},
		}
	}
</script>
<style>
	page {
		background: none;
	}

	.enterprise-search>>>.el-form-item__content {
		display: flex;
	}

	.el-submenu .el-menu-item {
		min-width: 100%;
	}
</style>
<style lang="less" scoped>
	.enterprise {
		margin: 0 auto;
		width: 68.5vw;
		/* 边距 */
		padding-top: 50px;
		box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.13);
		background: #fff;

		.menuContent {
			background: #fff;
			padding: 0.5vw 2.083vw 1.5625vw;
		}

		.packageUse {

			.takeEffect {
				display: flex;
				flex-wrap: wrap;

				.info {
					margin: 24px24px0 0;
					width: 324rpx;
					background-color: rgba(38, 140, 255, 0.05);
					box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.02);
					border-radius: 8rpx;
					font-size: 1.35vw;
					color: #333;

					.enterprises {
						margin-top: 30rpx;
						display: flex;
						justify-content: space-between;
						align-items: center;
						color: #999;
						font-size: 1.15vw;
					}
				}

				.check {
					color: #2089ff;
					border: 1px solid #2089ff;
				}
			}
		}
	}

	.manageInfo {
		.manageInfo-title {
			font-size: 1.0416vw;
		}

		.el-form {
			margin-top: 2.604vw;

			.el-input {
				width: 15.625vw;
				margin-right: 0.52vw;
			}
		}

		.clearfix {
			// float: right;
			margin-left: 21.3vw;
		}

		.el-table {
			margin-top: 10px;
			// margin-top: 2.083vw;
		}

		.tableBottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.833vw 0 6.25vw;
		}
	}
</style>