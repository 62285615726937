<template>
	<div>
		<div class="enterprise">
			<div style="background-color: #fff">
				<el-row>
					<gsLeft :userFormW="subform"></gsLeft>
					<el-col :span="20">
						<div class="menuContent">
							<div style="font-size: 20px">
								{{ pageParams.id ? "修改招聘信息" : "发布招聘信息" }}
							</div>
							<div class="tableContent">
								<el-form label-position="left" label-width="5.8vw" :model="recruitmentInfo">
									<div class="items">
										<div class="borderLeft">招聘信息</div>
										<div class="info" style="margin-top: 1.5625vw">
											<el-row>
												<el-col :span="22">
													<el-form-item label="职务名称：">
														<div style="display: flex; position: relative">
															<span style="
                                  position: absolute;
                                  top: 3px;
                                  left: -0.78vw;
                                  color: red;
                                ">*</span>
                              <div v-if="pageParams.id">
                                <el-checkbox-group v-model="recruitmentInfo.job">
                                  <el-checkbox v-for="item in recruitmentInfo.job"
                                               :label="item" :key="item"></el-checkbox>
                                </el-checkbox-group>
                              </div>
                              <div v-else>
                                <el-checkbox-group v-model="recruitmentInfo.job">
                                  <el-checkbox v-for="(item,key) in jobs"
                                               :label="item.label" :key="key"></el-checkbox>
                                </el-checkbox-group>
                              </div>
														</div>
													</el-form-item>
												</el-col>
											</el-row>
											<el-row>
												<el-col style="float: left" :span="11">
													<el-form-item label="证书等级：">
														<div style="display: flex; position: relative">
															<span style="
                                  position: absolute;
                                  top: 3px;
                                  left: -0.78vw;
                                  color: red;
                                ">*</span>
															<el-select v-model="recruitmentInfo.level"
																placeholder="请选择">
																<el-option v-for="item in certificates"
																	:key="item.value" :label="item.label"
																	:value="item.value"></el-option>
															</el-select>
														</div>
													</el-form-item>
												</el-col>
												<el-col style="float: right" :span="11">
													<el-form-item label="截止时间：">
														<!-- <el-date-picker
						      v-model="recruitmentInfo."
						      type="date"
									value-format="yyyy-MM-dd"
						      placeholder="请选择">
						    </el-date-picker> -->
														<div style="display: flex; position: relative">
															<el-select v-model="recruitmentInfo.expiredDate"
																placeholder="请选择">
																<el-option v-for="item in deadlines" :key="item.value"
																	:label="item.label" :value="item.value"></el-option>
															</el-select>
														</div>
													</el-form-item>
												</el-col>
											</el-row>
											<el-row>
												<el-col :span="11">
													<el-form-item label="提供月薪：">
														<!-- <el-input style="width:180px" v-model="recruitmentInfo.salaryFloor" placeholder="请输入最低月薪" @change="highestInput"></el-input> <span style="margin: 0 15px">~</span> -->
														<div style="display: flex; position: relative">
															<el-input v-model="recruitmentInfo.salaryRoof"
																placeholder="请输入月薪" @change="highestInput"></el-input>
														</div>
													</el-form-item>
													<!-- <el-form-item label="特殊证书：">
						 <el-cascader
						 v-model="certificate"
						 :placeholder="recruitmentInfo.certificate ? recruitmentInfo.certificate.name : '请选择'"
                          separator="-"
                          :options="option"
                          @change="handleChanges"
                        >
                        </el-cascader>
					</el-form-item> -->
												</el-col>
												<el-col style="float: right" :span="11">
													<el-form-item label="船舶类型：">
														<div style="display: flex; position: relative">
															<span style="
                                  position: absolute;
                                  top: 3px;
                                  left: -0.78vw;
                                  color: red;
                                ">*</span>
															<!-- <el-select
							v-model="recruitmentInfo.shipType"
							placeholder="请选择"
						>
							<el-option
								v-for="item in ships"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select> -->
															<el-input v-model="recruitmentInfo.shipType"
																placeholder="请输入船舶类型" />
														</div>
													</el-form-item>
												</el-col>
											</el-row>
											<el-row>
												<el-col :span="11">
													<el-form-item label="船舶吨位：">
														<!-- <el-select
							v-model="recruitmentInfo.tonnage"
							placeholder="请选择"
						>
							<el-option
								v-for="item in tonnages"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select> -->
														<div style="display: flex; position: relative">
															<span style="
                                  position: absolute;
                                  top: 3px;
                                  left: -0.78vw;
                                  color: red;
                                ">*</span>
															<el-input v-model="recruitmentInfo.tonnage"
																placeholder="例：1万-2万"></el-input>
														</div>
													</el-form-item>
												</el-col>
												<el-col style="float: right" :span="11">
													<el-form-item label="航行区域：">
														<div style="display: flex; position: relative">
															<span style="
                                  position: absolute;
                                  top: 3px;
                                  left: -0.78vw;
                                  color: red;
                                ">*</span>
															<el-select v-model="recruitmentInfo.sailLine"
																placeholder="请选择">
																<el-option v-for="item in lines" :key="item.value"
																	:label="item.label" :value="item.value"></el-option>
															</el-select>
														</div>
													</el-form-item>
												</el-col>
											</el-row>
											<el-row>
												<el-col :span="11">
													<el-form-item label="船龄：">
														<div style="display: flex; position: relative">
															<span style="
                                  position: absolute;
                                  top: 3px;
                                  left: -0.78vw;
                                  color: red;
                                ">*</span>
															<!-- <el-select v-model="recruitmentInfo.shipYear" filterable allow-create default-first-option placeholder="请选择">
						    <el-option
						      label="1年"
						      value="1年">
						    </el-option>
								<el-option
						      label="2年"
						      value="2年">
						    </el-option>
								<el-option
						      label="3年"
						      value="3年">
						    </el-option>
						  </el-select> -->
															<el-input v-model="recruitmentInfo.shipYear"
																placeholder="请输入船龄" />
														</div>
													</el-form-item>
												</el-col>
												<el-col style="float: right" :span="11">
													<el-form-item label="上船时间：">
														<div style="display: flex; position: relative">
															<span style="
                                  position: absolute;
                                  top: 3px;
                                  left: -0.78vw;
                                  color: red;
                                ">*</span>
															<el-input v-model="recruitmentInfo.boardingTime"
																placeholder="例：2021-12-12"></el-input>
														</div>
													</el-form-item>
												</el-col>
											</el-row>
											<el-row>
												<el-col :span="11">
													<el-form-item label="上船地点：">
														<div style="display: flex; position: relative">
															<span style="
                                  position: absolute;
                                  top: 3px;
                                  left: -0.78vw;
                                  color: red;
                                ">*</span>
															<el-input v-model="recruitmentInfo.boardingArea"
																placeholder="请输入上船地点"></el-input>
														</div>
													</el-form-item>
												</el-col>
												<el-col style="float: right" :span="11">
													<el-form-item label="合同期：">
														<div style="display: flex; position: relative">
															<el-input v-model="recruitmentInfo.contractTerm"
																placeholder="请输入合同期"></el-input>
														</div>
													</el-form-item>
												</el-col>
											</el-row>
											<!-- <el-row>
								<el-col :span="11"> -->
											<el-form-item label="备注：">
												<el-input type="textarea" :autosize="{ minRows: 4 }"
													v-model="recruitmentInfo.memo">
												</el-input>
											</el-form-item>
											<el-form-item label="是否公开：">
												<div style="
                            display: flex;
                            position: relative;
                            align-items: center;
                            height: 40px;
                          ">
													<span style="
                              position: absolute;
                              top: 3px;
                              left: -0.78vw;
                              color: red;
                            ">*</span>
													<el-radio v-model="recruitmentInfo.isOpen"
														:label="true">公开</el-radio>
													<el-radio v-model="recruitmentInfo.isOpen"
														:label="false">不公开</el-radio>
												</div>
											</el-form-item>
											<!-- </el-col>
								<el-col style="float: right" :span="11"> -->
											<!-- </el-col>
							</el-row> -->
										</div>
									</div>
									<div class="items">
										<div class="borderLeft">联系方式</div>
										<div class="relation" style="margin-top: 2.083vw">
											<el-radio v-model="contractsIndex" :label="idx"
												v-for="(item, idx) in contracts" :key="idx"
												:disabled="item.disabled">{{ item.name }}</el-radio>
										</div>
										<div style="margin-top: 1.5625vw" class="contract">
											<div v-for="(item, idx) in contracts" :key="idx">
												<div v-show="contractsIndex == idx">
													<el-form-item label="联系人：">
														<el-input disabled v-model="item.contract"
															placeholder="请输入联系人姓名"></el-input>
													</el-form-item>
													<el-form-item label="对应称谓：">
														<el-select disabled v-model="item.sex" placeholder="请选择"
															clearable>
															<el-option label="先生" value="1"></el-option>
															<el-option label="女士" value="2"></el-option>
														</el-select>
													</el-form-item>
													<el-form-item label="部门/职务：">
														<el-input disabled v-model="item.job"
															placeholder="请输入部门/职务"></el-input>
													</el-form-item>
													<el-form-item label="固定电话：">
														<el-input disabled v-model="item.phone"
															placeholder="请输入固定电话"></el-input>
													</el-form-item>
													<el-form-item label="QQ/微信：">
														<el-input disabled v-model="item.msnQq"
															placeholder="请输入QQ/微信"></el-input>
													</el-form-item>
													<el-form-item label="手机号码：">
														<el-input disabled v-model="item.mobile"
															placeholder="请输入手机号码"></el-input>
													</el-form-item>
													<el-form-item label="电子邮箱：">
														<el-input disabled v-model="item.email"
															placeholder="请输入电子邮箱"></el-input>
													</el-form-item>
													<!-- <el-form-item label="中介担保:">
                  <el-switch disabled @change="isAgentChage"
                    v-model="item.isAgent">
                  </el-switch>
                </el-form-item> -->
												</div>
											</div>
										</div>
									</div>
									<el-alert
										title="请确保填写的内容中不含违法违规信息，一经发现，我们将删除该条信息。如发现贵公司发布的信息中多次出现同样的问题，我们将暂停贵公司的相关服务。"
										type="warning" show-icon :closable="false">
									</el-alert>
								</el-form>
							</div>
							<div class="bottomBtn">
								<el-button v-if="pageParams.id" type="default" style="background-color: #fff"
									@click="winBack">取消</el-button>
								<el-button type="primary" @click="submit"
									style="background-color: #2089ff">{{ pageParams.id ? "保存修改" : "发布" }}</el-button>
							</div>
						</div>
						<el-dialog title="提示" :visible.sync="showSub" width="30%">
							<span v-if="subform.subscribe" style="margin-right: 20px">是否关闭订阅</span>
							<span v-else style="margin-right: 20px">是否开启订阅</span>
							<el-radio v-model="subscription" :label="true">是</el-radio>
							<el-radio v-model="subscription" :label="false">否</el-radio>
							<span slot="footer" class="dialog-footer">
								<el-button @click="showSub = false">取 消</el-button>
								<el-button type="primary" @click="editSub">确 定</el-button>
							</span>
						</el-dialog>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	import BaseMixin from "@/mixins/BaseMixin.vue";
	import gsLeft from "@/component/gs_left.vue";
	export default {
		mixins: [BaseMixin],
		components: {
			gsLeft
		},
		data() {
			return {
				checkIndex: "4-1",
				recruitmentInfo: {
					job: [],
					isOpen: true,
				},
				certificate: [],
				contractsIndex: 0,
				contracts: [],
				option: [],
				lines: [],
				ships: [],
				tonnages: [],
				certificates: [],
				jobs: [],
				deadlines: [{
						value: "3天",
						label: "3天",
					},
					{
						value: "7天",
						label: "7天",
					},
					{
						value: "14天",
						label: "14天",
					},
					{
						value: "30天",
						label: "30天",
					},
					{
						value: "60天",
						label: "60天",
					},
					{
						value: "90天",
						label: "90天",
					},
				],
				showSub: false,
				subscription: true,
				subform: {},
			};
		},
		watch: {
			recruitmentInfo: {
				handler(newValue, oldValue) {
					console.log("newValue", newValue);
					localStorage.setItem("S_recruitmentInfo", JSON.stringify(newValue));
				},
				deep: true, // 深度监听对象内部属性的变化
			},
		},
		methods: {
			initData() {
				this.subform = {
					id: this.vuex_user.id,
					nickName: this.vuex_user.nickName,
					avatar: this.vuex_user.avatar,
					gender: this.vuex_user.gender,
					openId: this.vuex_user.openId,
					wxOpenid: this.vuex_user.wxOpenid,
					phone: this.vuex_user.phone,
					password: this.vuex_user.password,
					name: this.vuex_user.name,
					simpleName: this.vuex_user.simpleName,
					province: this.vuex_user.province,
					city: this.vuex_user.city,
					district: this.vuex_user.district,
					address: this.vuex_user.address,
					postalCode: this.vuex_user.postalCode,
					website: this.vuex_user.website,
					intro: this.vuex_user.intro,
					subscribe: this.vuex_user.subscribe,
					isPass: this.vuex_user.isPass,
					openAgency: this.vuex_user.openAgency,
					createTime: this.vuex_user.createTime,
					autid: this.vuex_user.autid,
				};
				this.subscription = this.subform.subscribe;
				if (this.pageParams.id) {
					this.$u.api.recruitInfo({
						id: this.pageParams.id
					}).then((res) => {
						this.recruitmentInfo = res.data.recruit;
            this.recruitmentInfo.job = this.recruitmentInfo.job.split(",");
						this.$u.api.getCompanyContracts().then((res) => {
							res.data.forEach((e, index) => {
								if (this.pageParams.id) {
									if (e.name == this.recruitmentInfo.contract.name) {
										this.contractsIndex = index;
									}
								}
							});
						});
					});
				}else{
          try {
            const strInfo = JSON.parse(localStorage.getItem("S_recruitmentInfo"));
            if (strInfo && strInfo !== "undefined") {
              this.recruitmentInfo = JSON.parse(localStorage.getItem("S_recruitmentInfo"));
              if(this.recruitmentInfo.job){
                if (typeof this.recruitmentInfo.job === "string") {
                  this.recruitmentInfo.job = [this.recruitmentInfo.job];
                }
              }else{
                this.recruitmentInfo.job = [];
              }
              console.log("recruitmentInfo", this.recruitmentInfo)

              // this.$confirm("是否使用上次存储的表单内容?", "提示", {
              //   confirmButtonText: "确定",
              //   cancelButtonText: "取消",
              //   type: "warning",
              // }).then(() => {
              //   this.recruitmentInfo = JSON.parse(localStorage.getItem("S_recruitmentInfo"));
              // });
            }
          } catch (error) {
            console.log("1-",error);
          }
        }
				this.$u.api.resumeCertificate().then((res) => {
					let arr = [];
					let len = res.data.length;
					for (let i = 0; i < len; i++) {
						arr.push({
							label: res.data[i].name,
							value: res.data[i].id,
							children: res.data[i].certificateDetails,
						});
					}
					arr.forEach((e) => {
						e.children.forEach((ep) => {
							ep.value = ep.id;
						});
					});

					this.option = arr;
				});
				this.$u.api
					.dict({
						keyStr: "job,certificate_level,tonnage,sail_line,ship_year,ship_type"
					})
					.then((res) => {
						this.selectItem(res.data.sail_line, this.lines);
						this.selectItem(res.data.ship_type, this.ships);
						this.selectItem(res.data.tonnage, this.tonnages);
						this.selectItem(res.data.certificate_level, this.certificates);
						this.selectItem(res.data.job, this.jobs);
					});
				this.$u.api.getCompanyContracts().then((res) => {
					res.data.forEach((e, index) => {
						e.disabled = false;
						if (e.contract === "") {
							e.disabled = true;
						}
						e.sex = e.sex ? e.sex + "" : "";
					});
					this.contracts = res.data;
					if (!this.pageParams.id) {
						this.recruitmentInfo.contracts = res.data;
					}
					// }
					// console.log(res.data);
				});
			},
			handleChanges(e) {
				this.recruitmentInfo.certificateId = e[1];
				// console.log(this.resumeList.certificateParams);
			},
			highestInput(e) {
				// if(this.recruitmentInfo.salaryFloor && this.recruitmentInfo.salaryRoof) {
				// 	if(this.recruitmentInfo.salaryFloor > this.recruitmentInfo.salaryRoof) {
				// 		this.uni.showToast({title:'最高月薪不可低于最低月薪',icon:'none'})
				// 		this.recruitmentInfo.salaryRoof = ''
				// 		return
				// 	}
				// }
			},
			submit() {
				this.recruitmentInfo.contractId = this.contracts[this.contractsIndex].id;
				delete this.recruitmentInfo.contracts;
				// this.recruitmentInfo.certificateId = 1
				this.recruitmentInfo.salaryFloor = 0;
				// this.recruitmentInfo.salaryRoof = Number(this.recruitmentInfo.salaryRoof)
				if (!this.recruitmentInfo.job) {
					this.uni.showToast("请输入要招聘的职务名称");
					return;
				}
				if (!this.recruitmentInfo.level) {
					this.uni.showToast("请选择证书等级");
					return;
				}
				if (!this.recruitmentInfo.shipType) {
					this.uni.showToast("请选择船舶类型");
					return;
				}
				if (!this.recruitmentInfo.tonnage) {
					this.uni.showToast("请选择船舶吨位");
					return;
				}
				if (!this.recruitmentInfo.sailLine) {
					this.uni.showToast("请选择航行区域");
					return;
				}
				if (!this.recruitmentInfo.shipYear) {
					this.uni.showToast("请输入船龄");
					return;
				}
				if (!this.recruitmentInfo.boardingTime) {
					this.uni.showToast("请输入上船时间");
					return;
				}
				if (!this.recruitmentInfo.boardingArea) {
					this.uni.showToast("请输入上船地点");
					return;
				}
				// if (!this.recruitmentInfo.contractTerm) {
				//   this.uni.showToast("请输入合同期");
				//   return;
				// }
				// if (!this.recruitmentInfo.expiredDate) {
				//   this.uni.showToast("请选择截止日期");
				//   return;
				// }
				// if (!this.recruitmentInfo.salaryRoof) {
				//   this.uni.showToast("请输入月薪");
				//   return;
				// }
				if (this.recruitmentInfo.isOpen == undefined) {
					this.uni.showToast("请选择是否公开");
					return;
				}

				if (!this.pageParams.id) {
					this.$u.api.editRecruit(this.recruitmentInfo).then((res) => {
						this.uni.showToast("招聘创建成功");
						setTimeout(() => {
							this.winBack();
						}, 500);
					});
				} else {
					// if(!this.recruitmentInfo.certificateId) {
					// 	this.recruitmentInfo.certificateId = this.recruitmentInfo.certificate.id
					// }
					delete this.recruitmentInfo.company;
					delete this.recruitmentInfo.contract;
					delete this.recruitmentInfo.isTop;
					delete this.recruitmentInfo.topEndDate;
					delete this.recruitmentInfo.updateTime;
					// delete this.recruitmentInfo.createTime
					delete this.recruitmentInfo.certificate;

					this.$u.api.editRecruit(this.recruitmentInfo).then((res) => {
						this.uni.showToast({
							title: "招聘修改成功",
							icon: "none"
						});
						setTimeout(() => {
							this.jump("manage_recruitment_info");
						}, 400);
					});
				}
			},
			selectItem(item, arr) {
				item.forEach((e) => {
					let obj = {
						label: e.label,
						value: e.value,
					};
					arr.push(obj);
				});
			},
			editSub() {
				if (this.subscription) {
					this.$u.api.updateSubscribe(this.subscription + "").then((res) => {
						this.uni.showToast({
							title: "订阅开启成功",
							icon: "none"
						});
						this.subform.subscribe = true;
						this.$u.vuex("vuex_user", this.subform);
						this.showSub = false;
					});
				} else {
					this.$u.api.updateSubscribe(this.subscription + "").then((res) => {
						this.uni.showToast({
							title: "订阅关闭成功",
							icon: "none"
						});
						this.subform.subscribe = false;
						this.$u.vuex("vuex_user", this.subform);
						this.showSub = false;
					});
				}
			},
			check(index) {
				this.checkIndex = index;
				if (index == "1") {
					this.jump("enterprise_index");
				} else if (index == "2") {
					this.jump("ec_account_manage");
				} else if (index == "3") {
					this.jump("ec_buy_package");
				} else if (index == "4-1") {
					this.jump("post_recruitment_info");
				} else if (index == "4-2") {
					this.jump("manage_recruitment_info");
				} else if (index == "4-3") {
					this.jump("resume_delivery_received");
				} else if (index == "4-4") {
					this.jump("collected_resume");
				} else if (index == "5-1") {
					this.jump("ec_training_info");
				} else if (index == "5") {
					this.jump("ec_training_order");
				} else if (index == "5-3") {
					this.jump("training_school");
				} else if (index == "7") {
					this.jump("corporate_announcements");
				} else if (index == "8") {
					// this.$u.api.companyLoginInfo().then(res => {
					// 	if(res.data.openAgency) {
					if (this.vuex_user.openAgency) {
						this.jump("ec_mediation_guarantee");
					} else {
						this.uni.showToast({
							title: "您未开通中介担保",
							icon: "none"
						});
					}
					// })
				} else if (index == "9") {
					this.jump("published_data");
				} else if (index == "10") {
					this.showSub = true;
				}
			},
		},
	};
</script>
<style>
	page {
		background: none;
	}

	.el-alert--warning.is-light>>>.el-alert__title {
		color: #333333;
	}

	.el-form>>>.el-form-item__label {
		font-size: 0.833vw;
	}

	.el-submenu .el-menu-item {
		min-width: 100%;
	}
</style>
<style lang="less" scoped>
	.enterprise {
		margin: 0 auto;
		width: 68.5vw;
		/* 边距 */
		padding-top: 30px;
		background: #fff;
		box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.13);

		.menuContent {
			background: #fff;
			padding: 1.5vw 2.083vw 1.5625vw;
			font-size: 16px;

			.tableContent {

				// padding: 2.083vw 0 0 0;
				.items {
					margin-top: 3.125vw;
				}

				.contract {

					.el-input,
					.el-select,
					.el-cascader {
						width: 20.833vw;
					}
				}

				.info {

					.el-input,
					.el-select,
					.el-cascader {
						width: 16.04vw;
					}
				}

				.borderLeft {
					border-left: 0.208vw solid #2089ff;
					font-size: 1.04vw;
					color: #333;
					font-weight: 500;
					padding: 0 0 0 0.52vw;
					margin: 0;
					line-height: 1.04vw;
				}

				.el-form {
					margin-top: 2.083vw;
				}

				.el-alert--warning.is-light {
					background-color: #fff;
					margin-top: 2.08vw;
				}
			}

			.bottomBtn {
				margin-top: 3.125vw;
				display: flex;
				// text-align: center;
				margin-bottom: 3.125vw;

				margin-left: 100px;

				button {
					margin: 0;
					width: 6.25vw;
					font-size: 0.833vw;

					&:last-child {
						margin-left: 50px;
					}
				}
			}

			&:first-child {
				margin-right: 30px;
			}
		}
	}
</style>